import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'index',
    component: index,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/product',
    name: "product",
    component: () => import('../views/product.vue'),
  },
  {
    path: '/contact',
    name: "contact",
    component: () => import('../views/contact.vue')
  },
  {
    path: '/join',
    name: "join",
    component: () => import('../views/join.vue')
  },
  {
    path: '/news',
    component: () => import('../views/news.vue'),
    children: [
      {
        path: '',
        name:"newsList",
        component:()=>import('../views/news/list.vue')
      },
      {
        path:'detail',
        name:'detail',
        component:()=>import('../views/news/detail.vue')
      }
    ]
  },
  {
    path:"/ProductList",
    component:()=>import("../views/ProductList.vue"),
    children:[
      {
        path:"b2b2cMail",
        component:()=>import('../views/product/b2b2cMail.vue')
      },
      {
        path:"smartConstructionSite",
        component:()=>import('../views/product/smartConstructionSite.vue')  
      },
      {
        path:'marketingActivity',
        component:()=>import('../views/product/markAct.vue')
      },
      {
        path:'wx',
        component:()=>import('../views/product/wx.vue')
      },
      {
        path:'smartCard',
        component:()=>import('../views/product/smartCard.vue')
      },
      {
        path:'cardVoucherSystem',
        component:()=>import('../views/product/cardSystem.vue')
      },
      {
        path:'cloud',
        component:()=>import('../views/product/cloud.vue')
      },
      {
        path:'baas',
        component:()=>import('../views/product/baas.vue')
      }
    ]
  }

]


const router = new VueRouter({
  mode: 'history',
  //base:'/',
  base: process.env.BASE_URL,
  routes,

});

export default router
