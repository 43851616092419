<template>
  <div>
    <div class="num">
      <div class="icon">{{ digita.uni }}</div>

      <span>{{ animatedNumber }}</span>
    </div>
    <div class="text">
      <p class="title">{{ digita.title }}</p>
      <p v-html="digita.text"></p>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import eventBus from "../utils/eventBus.js";
export default {
  props: ["digita"],
  mounted() {
    eventBus.$on("showNumComponent", () => {
      this.number = this.digita.num;
    });
  },
  watch: {
    number: function (newValue) {
      gsap.to(this.$data, { duration: 2, tweenedNumber: newValue });
    },
  },
  computed: {
    animatedNumber: function () {
      return this.tweenedNumber.toFixed(0);
    },
  },
  data() {
    return {
      number: 0,
      tweenedNumber: 0,
    };
  },
};
</script>

<style>
</style>