<template>
  <footer>
    <div class="content">
      <div class="bage"></div>
      <div class="mid">
        <div class="product">
          <div class="title">产品和解决方案</div>
          <div
            class="item"
            v-for="i in productList"
            :key="i.id"
           
          >
           <a :href="'/product?id='+i.did">{{ i.title }}</a> 
          </div>
           <!-- @click="toProduct('#' + i.did)" -->
        </div>
        <div class="link">
          <div class="title">快速链接</div>
          <div v-for="i in linkList" :key="i.id">
            <router-link tag="a" :to="i.url" class="item">{{
              i.name
            }}</router-link>
          </div>
        </div>
        <div class="address">
          <div class="tel">400-0000-000</div>
          <div class="title">TEL</div>
          <div class="item">0538-0000000</div>
          <div class="title">MOB</div>
          <div class="item">0538-0000000</div>
          <div class="title">ADD</div>
          <div class="item">山东省济南市市中区纬二路德亨大厦</div>
        </div>
      </div>
    </div>
    <hr />
    <p>南京欣网互联网络科技有限公司 ©2010-2019 版权所有</p>
    <p>ICP证：苏ICP备15005788号-3</p>
  </footer>
</template>

<script>
import product from "../utils/product.js";
export default {
  name: "foot",
  data() {
    return {
      productList: product.def,
      linkList: [
        { name: "关于我们", url: "/about" },
        { name: "联系我们", url: "/contact" },
        { name: "加入我们", url: "/join" },
        { name: "产品中心", url: "/product" },
      ],
    };
  },
  methods: {
    toProduct(e) {
      let route = this.$route.path;
      if (route === "/product") {
        document.querySelector(e).scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      } else {
        this.$router.push({ name: "product", params: { id: e } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  width: 100%;
  background-color: #212121;
  padding: 40px 0 13px 0;
  color: #fff;

  .content {
    width: 1260px;
    margin: 0 auto;
    .bage {
      width: 20px;
      height: 6px;
      background-color: #2196f3;
      border-radius: 3px;
      margin: 0px auto 35px 0;
    }
    .mid {
      display: flex;

      .address {
        margin: auto;
        margin-right: 0;
        text-align: right;
      }
      .link {
        margin-left: 115px;
      }
      .product div:first-child,
      .link div:first-child {
        margin-bottom: 44px;
      }

      .title {
        font-size: 16px;
        line-height: 48px;
        color: #ffffff;
      }
      .item,a {
        font-size: 14px;
        line-height: 26px;
        color: #c9c9c9;
      }
      .tel {
        font-size: 30px;
        line-height: 48px;
        margin-bottom: 33px;
      }
    }
  }
  hr {
    height: 1px;
    border: none;
    background-color: #323232;
  }
  p {
    margin: 0;
    text-align: center;
    font-size: 12px;
    line-height: 22px;
    color: #c9c9c9;
  }
}
</style>