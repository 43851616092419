<template>
  <div>
    <header>
      <div class="nav">
        <div class="logo"></div>
        <div class="nav-list">
          <router-link
            tag="a"
            to="/"
            active-class="active"
            class="nav-item"
            exact
            >首页</router-link
          >
          <router-link
            tag="a"
            to="/product"
            active-class="active"
            class="nav-item"
            @mouseover.native="showModel = true"
            >产品与解决方案
            <img src="../assets/img/index_nav_arrow.png" v-if="!showModel" />
            <img src="../assets/img/index_nav_arrow2.png" v-else />
          </router-link>
          <router-link tag="a" to="/news" active-class="active" class="nav-item"
            >资讯动态</router-link
          >
          <router-link
            tag="a"
            to="/about"
            active-class="active"
            class="nav-item"
            >关于我们</router-link
          >
        </div>
        <div class="search"></div>
      </div>
    </header>
    <transition name="fade">
      <div class="model" @mouseleave="showModel = false" v-if="showModel">
        <div class="content">
          <div
            class="product-item"
            v-for="product in productList"
            :key="product.id"
          >
            <div class="product-title">
              {{ product.title }}
            </div>
            <div class="type-list">
              <router-link
                tag="a"
                v-for="item in product.list"
                :key="item.name"
                class="type-item"
                :to="'/ProductList'+item.url"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import product from "../utils/product";
export default {
  name: "Heade",
  data() {
    return {
      showModel: false,
      productList: product.def,
     
    };
  },
  watch: {
    $route: {
      handler:function() {
        this.showModel = false
      },
    },
  },
  methods: {
    // toProduct(e) {
    //   let route = this.$route.path;
    //   if (route === "/product") {
    //     document.querySelector(e).scrollIntoView({
    //       behavior: "smooth",
    //       block: "center",
    //       inline: "nearest",
    //     });
    //   } else {
    //     this.$router.push({ name: "product", params: { id: e } });
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
header {
  width: 100%;
  height: 80px;
  background: #fff;
  position: fixed;
  top: 0px;
  z-index: 999;
}
.nav {
  margin: 0 auto;
  height: 80px;
  width: 1260px;
  // background: #ff0;
  display: flex;

  .logo {
    width: 251px;
    height: 90px;
    background: url("../assets/img/logo.png") no-repeat 100% center;
  }
  .nav-list {
    width: 560px;
    font-size: 18px;
    height: 33px;
    margin: 30px 0px auto auto;
    color: #515151;
    display: flex;
    justify-content: space-between;
    .nav-item:hover,
    .nav-item:active {
      color: #2397f3;
    }
    .nav-item {
      color: #515151;
    }
    img {
      vertical-align: -2px;
    }
  }
  .search {
    margin: 32px 0px auto 18px;
    width: 20px;
    height: 20px;
    background: url("../assets/img/index_search.png") no-repeat 100% 100%;
  }
}
.active {
  border-bottom: 2px solid #2397f3;
  color: #2397f3 !important;
}

.model {
  width: calc(100% - 40px);
  position: fixed;
  top: 100px;
  left: 20px;
  padding-bottom: 35px;
  padding-top: 23.5px;
  background: rgba(255, 255, 255, 0.98);
  box-shadow: 0px 0px 0px 0px rgba(34, 41, 55, 0.9);
  border-radius: 10px;
  z-index: 999;
  .content {
    margin: 0 auto;
    width: 1260px;
    height: 360px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .product-item {
      width: 420px;
      .product-title {
        height: 25px;
        line-height: 25px;
        font-size: 20px;
        color: #212121;
        margin: 23.5px 0;
        &::before {
          content: " ";
          display: inline-block;
          vertical-align: text-bottom;
          margin-right: 5px;
          width: 22px;
          height: 22px;
          background: url("../assets/img/index_down_menu_title.png");
        }
      }
      .type-list {
        width: 400px;
        display: flex;
        flex-wrap: wrap;
        a {
          display: block;
          text-decoration: none;
        }
        .type-item {
          width: 200px;
          font-size: 16px;
          line-height: 36px;
          color: #616161;
          &::before {
            content: " ";
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #e5ce9c;
            vertical-align: 2px;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-leave-to,
.fade-enter {
  opacity: 0;
}
</style>