<template>
  <div class="app" @scroll="hScroll">
    <Heade></Heade>
    <router-view></router-view>
    <Foot></Foot>
  </div>
</template>

<script>
import Heade from "./components/heade.vue";
import Foot from "./components/foot.vue";
import eventBus from "./utils/eventBus.js";
export default {
  components: {
    Heade,
    Foot,
  },
  mounted() {
    eventBus.$on("scrollTop",this.scrollTop);
  },
  methods: {
    scrollTop() {
      document.getElementsByClassName('app')[0].scrollTo(0,0)
    },
    hScroll(e) {
      const scrollTop = e.target.scrollTop;
      if (scrollTop >= 650) {
        eventBus.$emit("show7GridComponent");
      }
      if (scrollTop >= 3330) {
        eventBus.$emit("show5GridComponent");
      }
      if (scrollTop >= 2130) {
        eventBus.$emit("showNumComponent");
      }
    },
  },
};
</script>

<style lang="scss">

body,
html {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.app {
  height: 100%;
  width: 100%;
  overflow: auto;
}
.main{
  margin-top: 80px;
}
.content {
  width: 1260px;
  margin: 0 auto;
}
a{
  text-decoration: none;
}
img{
  vertical-align: top;
}
</style>

