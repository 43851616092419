<template>
  <div class="main" @scroll="hScroll">
    <!-- 轮播开始 -->
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="i in 3" :key="i">
        <div class="slide_wrap">
          <div class="img_wrap">
            <img src="../assets/img/index/banner.jpg" />
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <!-- 轮播图结束 -->
    <!-- 轮播下四小标题 -->
    <div class="neck">
      <div class="content">
        <div class="item">
          <img src="../assets/img/index/index_down_1.png" alt="技术智能创新" />
          <div>
            <p class="title">技术智能创新</p>
            <p class="info">
              采用智能技术，不断迭代与创新 提升服务和管理环节的效率
            </p>
          </div>
        </div>
        <!-- banner下四小标题 -->
        <div class="item">
          <img src="../assets/img/index/index_down_2.png" alt="解决方案" />
          <div>
            <p class="title">解决方案</p>
            <p class="info">场景化、智慧化的行业数字化解 决方案</p>
          </div>
        </div>
        <!-- banner下四小标题 -->
        <div class="item">
          <img src="../assets/img/index/index_down_3.png" alt="专业服务" />
          <div>
            <p class="title">专业服务</p>
            <p class="info">从咨询规划、研发集成到运维运营的端到端的专业服务</p>
          </div>
        </div>
        <!-- banner下四小标题 -->
        <div class="item">
          <img src="../assets/img/index/index_down_4.png" alt="企业规模" />
          <div>
            <p class="title">企业规模</p>
            <p class="info">在全国范围各个省份设当地运营营销中心</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 轮播下四小标题结束 -->
    <!-- 企业优势 -->
    <div class="adv">
      <h1>多行业产品与解决方案</h1>
      <p>近二十年持续研发投入，打造自主可控的软件产品组合</p>
      <div class="btn">产品与服务</div>
    </div>
    <!-- 7大产品 -->
    <div class="product-block">
      <div class="content">
        <div
          v-for="(i, index) in productList"
          :key="i.id"
          class="grid"
          @mouseover="gridIn(index)"
          @mouseleave="gridOut(index)"
          :style="{
            top: show7GridComponent ? 0 : '150px',
            opacity: show7GridComponent ? 1 : 0,
          }"
        >
          <!-- 选中状态 -->

          <div v-show="gridIndex == index" key="unHover">
            <div class="title1">
              <div class="icon"></div>
              <div class="bold">{{ i.title }}</div>
              <div class="l" v-if="i.line">/</div>
              <div class="l">{{ i.en }}</div>
            </div>
            <div class="list">
              <a class="item" v-for="e in i.list" :key="e.name" :href="'/ProductList'+e.url">
                {{ e.name }}
              </a>
            </div>
          </div>
          <!-- 非选中状态 -->
          <div v-show="gridIndex != index" key="hover">
            <div class="left-icon"></div>
            <div class="info">
              <div class="title">{{ i.title }}</div>
              <div class="stitle">{{ i.en }}</div>
              <div class="r">
                <div v-for="(j, index) in i.list" :key="index"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 案例 -->
    <div class="adv">
      <h1>18年项目沉淀 我们更加懂你</h1>
      <p>技术深耕 值得信赖</p>
      <div class="btn">案例赏析</div>
    </div>
    <swiper :options="exampleOptions" class="content">
      <swiper-slide ref="mySwiper">
        <div class="example">
          <img src="../assets/img/index/index_case_img_1.jpg" alt="APP产品" />
          <p>APP产品</p>
        </div>
      </swiper-slide>
      <swiper-slide ref="mySwiper">
        <div class="example">
          <img src="../assets/img/index/index_case_img_2.jpg" alt="新金贸花园智慧社区" />
          <p>新金贸花园智慧社区</p>
        </div>
      </swiper-slide>
      <swiper-slide ref="mySwiper">
        <div class="example">
          <img src="../assets/img/index/index_case_img_3.jpg" alt="可视化大屏设计平台" />
          <p>可视化大屏设计平台</p>
        </div>
      </swiper-slide>

      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
    <div class="adv" ref="numVN">
      <h1>深耕数字产品服务 成就行业新标杆</h1>
      <p>构建专业、高效、协同的互联网一体化服务</p>
    </div>
    <div class="data">
      <div class="content">
        <Gasp class="item" v-for="i in digitalData" :key="i.id" :digita="i">
        </Gasp>
      </div>
    </div>
    <!-- 荣誉 -->
    <div class="honor">
      <div class="content">
        <div class="title">
          <div class="num">19</div>
          <div class="round"></div>
          <div class="text">
            <div class="bold">项高新证书</div>
            <div>实力的做好证明</div>
          </div>
        </div>
        <div class="in">
          <div class="list">
            <div class="item" v-for="i in honorList" :key="i.year">
              <div class="title">{{ i.year }}年</div>
              <div class="text" v-for="j in i.list" :key="j">{{ j }}</div>
            </div>
          </div>
          <div>
            <img src="../assets/img/index/cate.png" alt="计算机软件著作权登记证书" />
          </div>
        </div>
      </div>
    </div>
    <!-- 五大核心 -->
    <div class="five">
      <div class="content">
        <div class="title">
          <div class="num">5</div>
          <div class="round"></div>
          <div class="text">
            <div class="bold">大核心能力</div>
            <div>为您提供全面支撑</div>
          </div>
        </div>
        <div
          class="card"
          v-for="i in fiveCard"
          :key="i.title"
          :style="{
            top: show5GridComponent ? 0 : '150px',
            opacity: show5GridComponent ? 1 : 0,
          }"
        >
          <div class="icon"></div>
          <div class="text">
            <div class="bold">{{ i.title }}</div>
            <div>{{ i.text }}</div>
          </div>
          <div class="btn">了解更多</div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="friend">
      <div class="adv">
        <h1>可靠值得信赖</h1>
        <p>越来越多的合作伙伴选择我们</p>
      </div>
      <div class="content">
        <div class="item" v-for="i in 12" :key="i"></div>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="new">
      <div class="adv">
        <h1>资讯动态</h1>
        <p>DYNAMIC INFORMATION</p>
      </div>
      <div class="content">
        <div class="more">
          <div class="long"></div>
          <div class="btn">more+</div>
        </div>
        <div class="newlist">
          <div class="new-item" v-for="i in 6" :key="i">
            <div class="title">
              <div class="num">26</div>
              <div>2021.03</div>
            </div>
            <div class="text">
              <div class="bold">“2021优秀互联网新媒体人招募计划”等你加入</div>
              <div>
                互联网行业每天都在面临新的挑战，诞生新的机遇。越来越多的人希
                望投身互联网信息行业。钛媒体集团作为新媒体和互联网信息领域…
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact">
      <div class="content">
        <div class="bg">
          <img src="../assets/img/index/index_about_img.jpg" alt="联系我们" />
          <span>联系我们</span>
        </div>
        <div class="top">
          <div class="title">
            <span>南京欣网互联网络科技有限公司</span>
            <span class="badge">山东区</span>
          </div>
          <div class="info">
            <div class="add">
              <div>
                <img src="../assets/img/index/index_about_icon1.png" alt="" />
                <span>0531-8888</span>
              </div>
              <div>
                <img src="../assets/img/index/index_about_icon2.png" alt="" />
                <span>0531-8888</span>
              </div>
              <div>
                <img src="../assets/img/index/index_about_icon3.png" alt="" />
                <span>xwshandong@xwtec.com</span>
              </div>
              <div>
                <img src="../assets/img/index/index_about_icon4.png" alt="" />
                <span>山东省济南市市中区纬二路德亨大厦</span>
              </div>
            </div>
            <div class="map" id="map"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import product from "../utils/product.js";
import Gasp from "../components/gasp.vue";
import eventBus from "../utils/eventBus.js";
export default {
  name: "Home",
  components: {
    Gasp,
  },
  data() {
    return {
      productList: product.index,
      digitalData: [
        {
          id: 1,
          title: "深耕行业18年",
          uni: "年",
          num: 18,
          text: "成立于2003年<br/>专注于互联网和系统集成领域",
        },
        {
          id: 2,
          title: "19个省级分支机构",
          uni: "个",
          num: 19,
          text: "与19个省级运营商<br/>建立了长期稳定的合作关系",
        },
        {
          id: 3,
          title: "300+合作伙伴",
          uni: "+",
          num: 300,
          text: "与国内多家知名互联网企业和<br/>平台达成长期战略合作伙伴关系",
        },
        {
          id: 4,
          title: "1000+人员规模",
          uni: "+",
          num: 1000,
          text: "产品经理/UI设计/运营/售前<br/>研发/管理/新媒体等专业人员团队",
        },
      ],
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
      },
      exampleOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
      },
      honorList: [
        {
          year: "2011-2012",
          list: ["高新技术企业证书"],
        },
        {
          year: "2013",
          list: [
            "软件企业证书",
            "江苏省科技型中小企业",
            "质量管理体系认证证书",
          ],
        },
        {
          year: "2014",
          list: [
            "江苏省规划布局内重点软件企业",
            "江苏省民营科技企业",
            "南京市优秀民营企业",
            "CMMI-3级",
            "（软件能力成熟度模型集成三级）认证",
          ],
        },
        {
          year: "2015",
          list: [
            "电信网码号资源使用证书（江苏省）",
            "电信网码号资源使用证书（国家级）",
            "信息系统集成及服务资质证书（叁级）",
            "职业健康安全管理体系认证证书",
            "环境管理体系认证证书",
          ],
        },
        {
          year: "2016-2018",
          list: [
            "国家规划布局内重点软件企业",
            "信息安全管理体系认证证书",
            "信息系统集成及服务资质证书（贰级）",
          ],
        },
        {
          year: "2019-2021",
          list: [
            "AAA级资信等级证书",
            "知识产权管理体系认证证书",
            "增值电信业务经营许可证书（全国呼叫中心）",
          ],
        },
      ],
      fiveCard: [
        {
          title: "App开发运营",
          text: "Android&iOS原生页面开发及整体运营一体化",
          url: "",
        },
        {
          title: "全流程电商开发运营",
          text: "单/多商户商城，保函各类营销/会员/分销等主流功能",
          url: "",
        },
        {
          title: "微信公众号+小程序开发运营",
          text: "微信平台应用开发、运营策划一步到位 ",
          url: "",
        },
        {
          title: "短视频拍摄运营",
          text: "专业团队操刀，输出高品质作品，强力引流不流失",
          url: "",
        },
        {
          title: "广告平台运营",
          text: "投放尽在掌握之中，广告运营势在必得",
          url: "",
        },
      ],
      gridIndex: -1,
      show7GridComponent: false,
      show5GridComponent: false,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {

    // let map = new window.AMap.Map("map", {
    //   zoom: 18, //级别
    //   center: [116.998606, 36.657339], //中心点坐标
    //   viewMode: "3D", //使用3D视图
    // });
    // let marker = new window.AMap.Marker({
    //   position: new window.AMap.LngLat(116.998406, 36.657439),
    //   title: "德亨大厦",
    // });
    // map.add(marker);
    eventBus.$on("show7GridComponent", () => {
      this.show7GridComponent = true;
    });
    eventBus.$on("show5GridComponent", () => {
      this.show5GridComponent = true;
    });
  },
  methods: {
     hScroll(e) {

      const scrollTop = e.target.scrollTop
      if(scrollTop >= 650) {
        eventBus.$emit("show7GridComponent")
      }
      if(scrollTop >= 3330) {
        eventBus.$emit("show5GridComponent")
      }
      if(scrollTop>=2130) {
        eventBus.$emit("showNumComponent")
      }
    },
    gridIn(e) {
      this.gridIndex = e;
    },
    gridOut() {
      this.gridIndex = -1;
    },
  },
};
</script>
<style lang="scss" scoped>


.slide_wrap {
  position: relative;
  width: 100%;
  height: 650px;
  background: #010d26;
  overflow: hidden;
  .img_wrap {
    position: absolute;
    top: 0;
    left: 50%;
    width: 2560px;
    height: 650px;
    transform: translateX(-50%);
    img {
      width: 2560px;
      height: 650px;
    }
  }
}
.neck {
  height: 52px;
  background-color: #f8f8f8;
  padding: 24px 0;
  .content {
    display: flex;
    justify-content: space-between;
    .item {
      width: 267px;
      height: 54px;
      display: flex;
      img {
        width: 54px;
        height: 54px;
        margin: auto;
        margin-right: 16px;
      }
      p {
        margin: 0;
      }
      .title {
        font-size: 14px;
        color: #000000;
        font-weight: bold;
      }
      .info {
        font-size: 14px;
        color: #818181;
      }
    }
  }
}
.adv {
  width: 100%;
  background: #ffffff;
  text-align: center;
  padding-top: 78px;
  padding-bottom: 39px;
  h1 {
    font-size: 36px;
    line-height: 22px;
    color: #000000;
  }
  p {
    font-size: 20px;
    line-height: 22px;
    color: #515151;
  }
  .btn {
    margin: 0 auto;
    width: 100px;
    height: 30px;
    background: url("../assets/img/index/index_5big_more.png");
    color: #1b65fb;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
  }
}
.product-block {
  background: #f5f5f5;
  padding: 25px 0;
  .content {
    display: grid;
    grid-template-columns: 328px 168px 213px 168px 328px;
    grid-template-rows: repeat(2, 218px);
    grid-gap: 14px;
    .grid {
      background: #ffffff;
      overflow: hidden;
      position: relative;
      top: 150px;
      opacity: 0;
      .left-icon {
        position: relative;
        left: -18px;
        top: -18px;
        width: 110px;
        height: 110px;
        background-color: #e5f3ff;
        border-radius: 50%;
      }
      .info {
        position: absolute;
        right: 16px;
        bottom: 21px;
        text-align: right;
        .title {
          font-size: 22px;
          color: #111111;
        }
        .stitle {
          line-height: 19px;
          font-size: 12px;
          color: #818181;
        }
        .r {
          display: flex;
          flex-direction: row-reverse;
          margin-top: 14px;

          div {
            width: 9px;
            height: 9px;
            background-color: #1b65fb;
            border-radius: 50px;
            margin-left: 4.5px;
            &:last-child {
              margin-left: 0;
            }
          }
        }
      }
      .title1 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 12px;
        color: #96bfff;
        font-size: 12px;
        .bold {
          font-size: 22px;
          color: #ffffff;
          margin-right: 9px;
        }
        .l {
          margin-top: 10px;
        }
        .icon {
          width: 51px;
          height: 51px;
          margin-right: 9px;
        }
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: 120px;
        margin-left: 12px;
        .item {
          font-size: 16px;
          color: #ffffff;
          line-height: 34px;
          &::before {
            content: " ";
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: #24427c;
            border-radius: 4px;
            margin-right: 29px;
          }
        }
      }
    }
    @for $s1 from 1 through 7 {
      $r: random();
      .grid:nth-child(#{$s1}) {
        transition: #{$r + 0.5}s;
        transition-property: top,opacity;
        background: url("../assets/img/index/index_product_bg#{$s1}.jpg")
          no-repeat
          100%;
        .left-icon {
          background: url("../assets/img/index/index_product_icon_#{$s1}.png")
            no-repeat
            100%;
        }
      }
      .grid:nth-child(#{$s1}):hover {
        background: url("../assets/img/index/index_product_hover_bg#{$s1}.jpg")
          no-repeat
          100%;
        .icon {
          background: url("../assets/img/index/index_product_hover_icon_#{$s1}.png")
            no-repeat
            100%;
        }
      }
    }
    .grid:nth-child(1) {
      grid-column-start: span 2;
    }
    .grid:nth-child(2) {
      grid-row-start: span 2;
      .list {
        height: 250px;
        .item::before {
          margin-right: 5px;
        }
      }
    }
    .grid:nth-child(3) {
      .title1 {
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
      }
    }
    .grid:nth-child(4),
    .grid:nth-child(5) {
      .list {
        height: 150px;
      }
    }
    .grid:last-child {
      grid-column-start: span 2;
      .list {
        height: 150px;
      }
    }
  }
}

.example {
  height: 550px;
  width: 420px;
  img {
    width: 400px;
    height: 430px;
  }
  p {
    text-align: center;
    font-size: 18px;
    color: #313131;
  }
}
.data {
  ::v-deep .content {
    width: 1210px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 108px;
    .item {
      width: 250px;
      text-align: center;
      .num {
        position: relative;
        margin: 0 auto;
        width: 212px;
        height: 212px;
        line-height: 212px;
        margin-bottom: 53px;
        background: url("../assets/img/index/index_digital_bg.png") no-repeat
          100%;
        font-weight: bold;
        font-size: 70px;
        color: #2196f3;

        .icon {
          position: absolute;
          width: 36px;
          height: 36px;
          top: 23px;
          right: 14px;
          background-color: #e7ce9e;

          line-height: 36px;
          border-radius: 18px;
          font-size: 20px;
          font-weight: bold;
          color: #0d378e;
        }
      }
      .text {
        font-size: 16px;
        color: #919191;
        .title {
          font-size: 22px;
          color: #515151;
        }
      }
    }
  }
}

.honor {
  background-color: #1b2b4b;
  background-image: url("../assets/img/index/index_certificate_bg.jpg");
  background-repeat: no-repeat;
  background-size: 2560px 720px;
  background-position: center;

  .content {
    padding: 40px 0 60px 0;
    .title {
      display: flex;
      align-items: center;
      .num {
        font-weight: bold;
        font-size: 120px;
        color: #ffffff;
      }
      .round {
        margin: auto 16px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-image: linear-gradient(30deg, #2196f3 0%, #1b64fb 100%);
      }
      .text {
        color: #ffffff;
        font-size: 22px;
        .bold {
          margin-bottom: 13px;
          font-size: 26px;
          font-weight: bold;
        }
      }
    }
    .in {
      width: 1260px;
      display: flex;
      justify-content: space-between;
      position: relative;
      img{
        width: 600px;
        position: absolute;
        top: -40px;
        left: 690px;
      }
      .list {
        width: 285px * 2;
        height: 450px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
          width: 285px;
          font-size: 14px;
          line-height: 26px;
          color: #e5e5e5;
          .title {
            font-size: 18px;
            margin-bottom: 14px;
            font-weight: bold;
            background-image: linear-gradient(45deg, #2196f3 0%, #1b64fb 100%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }
}
.five {
  background: #f5f5f5;
  padding: 60px 0;
  .content {
    display: grid;
    grid-template-columns: repeat(3, 400px);
    grid-template-rows: repeat(2, 250px);
    grid-gap: 30px;
    .title {
      display: flex;
      align-items: center;
      color: #313131;
      .num {
        font-weight: bold;
        font-size: 120px;
      }
      .round {
        margin: auto 16px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-image: linear-gradient(30deg, #2196f3 0%, #1b64fb 100%);
      }
      .text {
        font-size: 22px;
        .bold {
          margin-bottom: 10px;
          font-size: 26px;
          font-weight: bold;
        }
      }
    }
    .card {
      background: #fff;
      padding: 20px;
      transition: 0.5s;
      position: relative;
      .icon {
        width: 60px;
        height: 60px;
      }
      .text {
        margin: 30px 0 40px 0;
        font-size: 14px;
        color: #717171;
        .bold {
          font-size: 22px;
          font-weight: bold;
          color: #313131;
        }
      }
      .btn {
        margin: auto;
        margin-right: 0;
        width: 100px;
        height: 30px;
        background: url("../assets/img/index/index_5big_more.png") no-repeat
          100%;
        color: #1b65fb;
        font-size: 14px;
        text-align: center;
        line-height: 30px;
      }
    }
    .card:hover {
      .text {
        color: #bad5fb;
        .bold {
          color: #ffffff;
        }
      }
      .btn {
        color: #e7d6b3;
        background: url("../assets/img/index/index_5big_hover_more.png")
          no-repeat 100%;
      }
    }
    @for $i from 1 through 5 {
      $r1: random();
      .card:nth-child(#{$i + 1}) {
        transition: #{$r1 + 0.5}s, background-color 0.5s;
        background: url("../assets/img/index/index_5bigr_bg#{$i}.jpg")
          no-repeat
          100%;
        .icon {
          transition: 0.5s;
          background: url("../assets/img/index/index_5big_icon#{$i}.png")
            no-repeat
            100%;
        }
      }
      .card:nth-child(#{$i + 1}):hover {
        background: url("../assets/img/index/index_5big_hover_bg#{$i}.jpg")
          no-repeat
          100%;
        .icon {
          transition: 0.5s;
          background: url("../assets/img/index/index_5big_hover_icon#{$i}.png")
            no-repeat
            100%;
        }
      }
    }
  }
}
.friend {
  .content {
    display: grid;
    grid-template-columns: repeat(6, 190px);
    grid-template-rows: repeat(2, 100px);
    grid-gap: 25px;
    .item {
      border: solid 1px #f1f1f1;
      border-radius: 3px;
      &:hover {
        box-shadow: 0px 0px 12px 1px rgba(36, 36, 38, 0.17);
        border-color: #1b6bfa;
      }
    }
    @for $f1 from 1 through 12 {
      .item:nth-child(#{$f1}) {
        background: url("../assets/img/index/index_partner_#{$f1}.jpg")
          no-repeat
          100%;
      }
    }
  }
}
.new {
  margin-bottom: 200px;
  .adv {
    padding-bottom: 0px;
    p {
      margin-bottom: 0;
    }
  }
  .content {
    .more {
      display: flex;
      align-items: center;
      width: 1260px;
      margin-bottom: 50px;
      .long {
        margin-left: 1260px / 2 - 50px / 2;
        width: 50px;
        height: 6px;
        background-image: linear-gradient(30deg, #2196f3 0%, #1b64fb 100%);
        border-radius: 3px;
      }
      .btn {
        margin: auto;
        margin-right: 0;
        width: 72px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        font-size: 14px;
        color: #1b65fb;
        background: url("../assets/img/index/index_news_more.png") no-repeat
          100%;
      }
    }
    .newlist {
      display: grid;
      grid-template-rows: repeat(3, 100px);
      grid-template-columns: repeat(2, 630px);
      grid-gap: 30px 0;
      .new-item {
        display: flex;
        .title {
          width: 86px;
          height: 90px;
          background-color: #e5f3ff;
          border-radius: 3px;
          margin-right: 10px;
          padding: 10px 0 0 14px;
          color: #3881ba;
          font-size: 18px;
          .num {
            width: 70px;
            height: 50px;
            background-color: #2196f3;
            border-radius: 3px 25px 25px 3px;
            font-size: 34px;
            line-height: 50px;
            text-align: center;
            font-weight: bold;
            color: #e5f3ff;
            margin-bottom: 14px;
          }
        }
        .text {
          width: 480px;
          font-size: 16px;
          line-height: 26px;
          color: #818181;
          .bold {
            font-size: 22px;
            color: #313131;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
.contact {
  padding-bottom: 100px;
  .content {
    position: relative;
  }
  .bg {
    display: flex;
    font-size: 40px;
    line-height: 75px * 2 + 40px;
    color: #2196f3;
    img {
      margin-right: 14px;
    }
  }
  .top {
    position: absolute;
    right: 0;
    bottom: -37px;
    width: 911px;
    background-color: #ffffff;
    box-shadow: 3px 2px 38px 2px rgba(36, 36, 38, 0.09);
    padding-left: 46px;
    padding-bottom: 16px;
    border-radius: 3px;
    .title {
      margin: 45px 0;
      font-size: 30px;
      color: #313131;
      font-weight: bold;
      .badge {
        font-size: 16px;
        background-color: #2196f3;
        padding: 0 5px;
        border-radius: 3px;
        color: #ffffff;
        margin-left: 13px;
      }
    }
    .info {
      display: flex;
      justify-content: space-between;
      align-content: flex-end;
      margin-right: 19px;
      .add {
        width: 480px;
        font-size: 20px;
        line-height: 50px;
        color: #515151;

        img {
          width: 28px;
          height: 22px;
          margin-right: 14px;
          vertical-align: -2px;
        }
      }
      .map {
        width: 406px;
        height: 203px;
        border-radius: 3px;
        border: solid 1px #e5e5e5;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-leave,
.fade-enter {
  opacity: 0;
}
</style>
