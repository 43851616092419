let product = {
    //默认数据（导航栏）
    def: [
        {
            id: 1,
            title: "电信运营商",
            en: "TELECOM OPERATORS",
            did: "TO",
            list: [
                {
                    name: "渠道运营",
                    url: "",
                },
                {
                    name: "数据分析",
                    url: "",
                },
                {
                    name: "客户运营",
                    url: "",
                },
                {
                    name: "安全风控",
                    url: "",
                },
                {
                    name: "实体渠道工具",
                    url: "",
                },
                {
                    name: "运维保障",
                    url: "",
                },
            ],
        },
        {
            id: 2,
            title: "智能销售",
            en: "INTELLIGENT MARKETING",
            did: "IM",
            list: [
                {
                    name: "智能名片",
                    url: "/smartCard"
                }, {
                    name: "营销活动平台",
                    url: "/marketingActivity"
                }, {
                    name: "B2B2C商城",
                    url: "/b2b2cMail"
                }, {
                    name: "微信公众号SaaS运营平台",
                    url: "/wx"
                }, {
                    name: "纷销平台",
                    url: ""
                }, {
                    name: "卡券营销",
                    url: "/cardVoucherSystem",
                }
            ]
        },
        {
            id: 3,
            title: "产业互联网",
            en: "INDUSTRIAL INTERNET",
            did: "II",
            list: [
                {
                    name: "智慧社区",
                    url: ""
                }, {
                    name: "智慧旅游",
                    url: ""
                }, {
                    name: "智慧工地",
                    url: "/smartConstructionSite"
                }, {
                    name: "智慧农业",
                    url: ""
                }, {
                    name: "智慧能源",
                    url: ""
                }, {
                    name: "智慧校园",
                    url: ""
                }, {
                    name: "智慧酒店",
                    url: ""
                }, {
                    name: "智慧党建",
                    url: ""
                }
            ]
        },
        {
            id: 4,
            title: "AI+大数据",
            en: "AI+BIG DATA",
            did: "AI",
            list: [
                {
                    name: "数据运营平台",
                    url: ""
                }, {
                    name: "可视化大屏系统",
                    url: ""
                }, {
                    name: "体验监控平台",
                    url: ""
                }, {
                    name: "RPA",
                    url: ""
                }
            ]
        },
        {
            id: 5,
            title: "5G",
            en: "5G",
            did: "FG",
            list: [
                { name: "云台席", url: "/cloud" }
            ]
        }, {
            id: 6,
            title: "区块链",
            en: "BLOCKCHAIN",
            did: "BC",
            list: [{
                name: "Baas平台",
                url: "/baas"
            }]
        }, {
            id: 7,
            title: "智能硬件",
            en: "SMART HARDWARE",
            did: "SH",
            list: [
                {
                    name: "三防手机、对讲机",
                    url: ""
                }, {
                    name: "智慧屏幕",
                    url: ""
                }, {
                    name: "闸机",
                    url: ""
                }, {
                    name: "智能手环、手表",
                    url: ""
                }
            ]
        }
    ],
    //首页数据
    index: [
        {
            id: 1,
            title: "电信运营商",
            en: "TELECOM OPERATORS",
            line: true,

            list: [
                {
                    name: "渠道运营",
                    url: "",
                },
                {
                    name: "数据分析",
                    url: "",
                },
                {
                    name: "客户运营",
                    url: "",
                },
                {
                    name: "安全风控",
                    url: "",
                },
                {
                    name: "实体渠道工具",
                    url: "",
                },
                {
                    name: "运维保障",
                    url: "",
                },
            ],
        },
        {
            id: 2,
            title: "智能销售",
            en: "INTELLIGENT MARKETING",
            list: [
                {
                    name: "智能名片",
                    url: "/smartCard"
                }, {
                    name: "营销活动平台",
                    url: "/marketingActivity"
                }, {
                    name: "B2B2C商城",
                    url: "/b2b2cMail"
                }, {
                    name: "微信公众号SaaS运营平台",
                    url: "/wx"
                }, {
                    name: "纷销平台",
                    url: ""
                }, {
                    name: "卡券营销",
                    url: "/cardVoucherSystem",
                }
            ]
        },
        {
            id: 3,
            title: "5G",
            en: "5G",
            list: [
                { name: "云台席", url: "/cloud" }
            ]
        },
        {
            id: 4,
            title: "智能硬件",
            en: "SMART HARDWARE",
            line: true,
            list: [
                {
                    name: "三防手机、对讲机",
                    url: ""
                }, {
                    name: "智慧屏幕",
                    url: ""
                }, {
                    name: "闸机",
                    url: ""
                }, {
                    name: "智能手环、手表",
                    url: ""
                }
            ]
        },
        {
            id: 5,
            title: "AI+大数据",
            en: "AI+BIG DATA",
            line: true,
            list: [
                {
                    name: "数据运营平台",
                    url: ""
                }, {
                    name: "可视化大屏系统",
                    url: ""
                }, {
                    name: "体验监控平台",
                    url: ""
                }, {
                    name: "RPA",
                    url: ""
                }
            ]
        }, {
            id: 6,
            title: "区块链",
            en: "BLOCKCHAIN",
            list: [{
                name: "Baas平台",
                url: "/baas"
            }]
        }, {
            id: 7,
            title: "产业互联网",
            en: "INDUSTRIAL INTERNET",
            line: true,
            list: [
                {
                    name: "智慧社区",
                    url: ""
                }, {
                    name: "智慧旅游",
                    url: ""
                }, {
                    name: "智慧工地",
                    url: "/smartConstructionSite"
                }, {
                    name: "智慧农业",
                    url: ""
                }, {
                    name: "智慧能源",
                    url: ""
                }, {
                    name: "智慧校园",
                    url: ""
                }, {
                    name: "智慧酒店",
                    url: ""
                }, {
                    name: "智慧党建",
                    url: ""
                }
            ]
        },
    ],

    //产品列表页数据
    ProductList: [
        {
            id: 1,
            title: "电信运营商",
            en: "TELECOM OPERATORS",
            did: "TO",
            img: require('../assets/img/product/product_list_title_1.png'),
            list: [
                {
                    name: "渠道运营",
                    icon: require('../assets/img/product/product_list_bg_1.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_1.png'),
                    url: "",
                },
                {
                    name: "客户运营",
                    icon: require('../assets/img/product/product_list_bg_2.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_2.png'),
                    url: "",
                },
                {
                    name: "实体渠道工具",
                    icon: require('../assets/img/product/product_list_bg_3.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_3.png'),
                    url: "",
                },
                {
                    name: "数据分析",
                    icon: require('../assets/img/product/product_list_bg_4.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_4.png'),
                    url: "",
                },
                {
                    name: "安全工具",
                    icon: require('../assets/img/product/product_list_bg_5.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_5.png'),
                    url: "",
                },
                {
                    name: "运维保障",
                    icon: require('../assets/img/product/product_list_bg_6.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_6.png'),
                    url: "",
                },
            ],
        },
        {
            id: 2,
            title: "产业互联网",
            en: "INDUSTRIAL INTERNET",
            did: "II",
            row: "row-3",
            img: require('../assets/img/product/product_list_title_2.png'),
            list: [
                {
                    name: "智慧社区",
                    class: "span-2",
                    icon: require('../assets/img/product/product_list_bg_7.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_7.png'),
                    url: ""
                }, {
                    name: "智慧工地",
                    icon: require('../assets/img/product/product_list_bg_8.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_8.png'),
                    url: "/smartConstructionSite",
                    subTitle: "智管云智能化工地管理平台"
                }, {
                    name: "智慧能源",
                    icon: require('../assets/img/product/product_list_bg_9.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_9.png'),
                    url: ""
                }, {
                    name: "智慧酒店",
                    icon: require('../assets/img/product/product_list_bg_10.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_10.png'),
                    url: ""
                }, {
                    name: "智慧旅游",
                    icon: require('../assets/img/product/product_list_bg_11.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_11.png'),
                    url: ""
                }, {
                    name: "智慧农业",
                    icon: require('../assets/img/product/product_list_bg_12.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_12.png'),
                    url: ""
                }, {
                    name: "智慧校园",
                    icon: require('../assets/img/product/product_list_bg_13.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_13.png'),
                    url: ""
                }, {
                    icon: require('../assets/img/product/product_list_bg_14.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_14.png'),
                    name: "智慧党建",
                    url: ""
                }
            ]
        },
        {
            id: 3,
            title: "智能销售",
            en: "INTELLIGENT MARKETING",
            did: "IM",
            img: require('../assets/img/product/product_list_title_2.png'),
            list: [
                {
                    name: "智能名片",
                    icon: require('../assets/img/product/product_list_bg_15.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_15.png'),
                    url: "/smartCard",
                    subTitle: "助力企业发展的SCRM产品"
                }, {
                    name: "B2B2C商城",
                    icon: require('../assets/img/product/product_list_bg_16.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_16.png'),
                    url: "/b2b2cMail",
                    subTitle: "支持自营+招商入驻经营模式的电商平台"
                }, {
                    name: "纷销平台",
                    icon: require('../assets/img/product/product_list_bg_17.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_17.png'),
                    url: ""
                }, {
                    name: "营销活动平台",
                    icon: require('../assets/img/product/product_list_bg_18.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_18.png'),
                    url: "/marketingActivity",
                    subTitle: "全流程一站式活动运营管理平台"
                }, {
                    name: "微信公众号SaaS运营平台",
                    icon: require('../assets/img/product/product_list_bg_19.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_19.png'),
                    url: "/wx",
                    subTitle: "公众号用户标签化&数据化运营平台"
                }, {
                    name: "卡券营销",
                    icon: require('../assets/img/product/product_list_bg_20.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_20.png'),
                    url: "/cardVoucherSystem",
                    subTitle: "承载拉新/促活/资源置换的营销模式"
                }
            ]
        },
        {
            id: 4,
            title: "AI+大数据",
            en: "AI+BIG DATA",
            did: "AI",
            img: require('../assets/img/product/product_list_title_4.png'),
            list: [
                {
                    name: "数据运营平台",
                    class: "span-2",
                    icon: require('../assets/img/product/product_list_bg_21.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_21.png'),
                    url: ""
                }, {
                    name: "体验监控平台",
                    icon: require('../assets/img/product/product_list_bg_22.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_22.png'),
                    url: ""
                }, {
                    name: "RPA",
                    icon: require('../assets/img/product/product_list_bg_23.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_23.png'),
                    url: ""
                }, {
                    name: "可视化大屏系统",
                    icon: require('../assets/img/product/product_list_bg_24.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_24.png'),
                    class: "span-2",
                    url: ""
                }
            ]
        },
        {
            id: 5,
            title: "5G",
            en: "5G",
            did: "FG",
            row: "row-1",
            img: require('../assets/img/product/product_list_title_5.png'),
            list: [
                {
                    name: "云台席",
                    icon: require('../assets/img/product/product_list_bg_25.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_25.png'),
                    url:"/cloud",
                    subTitle:"不管距离多远，“面对面服务”都能一触即达"
                }
            ]
        }, {
            id: 6,
            title: "区块链",
            en: "BLOCKCHAIN",
            did: "BC",
            img: require('../assets/img/product/product_list_title_6.png'),
            row: "row-1",
            list: [{
                name: "Baas平台",
                icon: require('../assets/img/product/product_list_bg_26.jpg'),
                bgurl: require('../assets/img/product/product_list_icon_26.png'),
                url: "/baas",
                subTitle:"提供便捷、高性能的区块链生态环境和生态配套服务"
            }]
        }, {
            id: 7,
            title: "智能硬件",
            en: "SMART HARDWARE",
            did: "SH",
            img: require('../assets/img/product/product_list_title_7.png'),
            list: [
                {
                    name: "三防手机、对讲机",
                    class: "span-2",
                    icon: require('../assets/img/product/product_list_bg_27.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_27.png'),
                    url: ""
                }, {
                    name: "闸机",
                    icon: require('../assets/img/product/product_list_bg_28.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_28.png'),
                    url: ""
                }, {
                    name: "智慧屏幕",
                    icon: require('../assets/img/product/product_list_bg_29.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_29.png'),
                    url: ""
                }, {
                    name: "智能手环、手表",
                    icon: require('../assets/img/product/product_list_bg_30.jpg'),
                    bgurl: require('../assets/img/product/product_list_icon_30.png'),
                    class: "span-2",
                    url: ""
                }
            ]
        }
    ],


}
export default product
